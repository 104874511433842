import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import bgImagePortrait from 'images/BackgroundVertical.jpg'
import LargeBackground from 'images/BackgroundHorizontal.jpg'
import { breakpoints } from 'components/ui-kit/globalValue'
import { Page } from 'components/layout/Page'
import { Container } from 'components/ui-kit/Container'
import { headerHeights } from 'components/ui-kit/globalValue'
import { Title } from 'components/ui-kit/title'
import { colors } from 'components/ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'

const NotFoundMain = styled.div`
    height: calc(100vh - ${headerHeights.mobile});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 518px;

    @media (orientation: portrait) {
        background-image: url(${bgImagePortrait});
    }
    @media (orientation: landscape) {
        background-image: url(${LargeBackground});
    }
    @media (min-width: ${breakpoints.tablet}) {
        height: calc(100vh - ${headerHeights.tablet});
    }
    @media (min-width: 1200px) {
        height: calc(100vh - ${headerHeights.desktop});
    }
`

const NotFoundContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: ${colors.mobileColor};
    text-align: center;
`

const IndexTitle = styled(Title)`
    && {
        margin: 0.5em 0;
    }
    text-shadow: 2px 2px 2px #222;
    color: ${colors.mobileColor};
`

const NotFoundCode = styled.div`
    font-size: 100px;

    @media (min-width: ${breakpoints.tablet}) {
        font-size: 140px;
    }

    @media (min-width: ${breakpoints.desktop}) {
        font-size: 180px;
    }
`

const NotFoundLabel = styled.div`
    font-size: 30px;
`

const ErrorPage = () => {
    const translations = useTranslations()
    return (
        <Page>
            <Helmet>
                <title>
                    404 - {translations.logo} -{translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content="404 - СПСК - Скала-подільський Спецкарʼєр.
                    "
                />
            </Helmet>
            <NotFoundMain alt="Фонове зображення">
                <NotFoundContainer>
                    <IndexTitle as="h1">{translations.siteTitle}</IndexTitle>

                    <NotFoundCode>404</NotFoundCode>

                    <NotFoundLabel> {translations.notFound}</NotFoundLabel>
                </NotFoundContainer>
            </NotFoundMain>
        </Page>
    )
}
export default ErrorPage
